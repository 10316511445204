import { Link } from '@inertiajs/inertia-react';
import React,{useEffect} from 'react'

export default function FAQDetail(props) {
    const {data} = props;
  return (
    <div>
        <div className="col-span-1 mb-4">
            <div className="flex flex-col gap-2 shadow-lg rounded bg-white p-8 md:p-8 sm:!p-2 xs:p-2 border-t-4 border-secondary">
                <h4 className="font-bold">
                    <Link  href={route("faq")}>{data.title}</Link>
                </h4>
                <p>
                {data.description && (
                        <div
                            className="text-justify"
                            dangerouslySetInnerHTML={{
                                __html:data.description
                            }}
                        />
                    )}
                </p>
            </div>
        </div>
    </div>
  )
}
