import { stateToHTML } from "draft-js-export-html";
import React,{useState,useEffect} from "react";
import FAQLink from "./FAQLink";
import FAQDetail from "./FAQDetail";

export default function FAQGrid({ data }) {
    const [isHome, setIsHome] = useState(false);

  useEffect(() => {
    // Check if the current path is the root path (home)
    setIsHome(window.location.pathname === '/');
  }, []);
    return (
        <div>
            {isHome?
            <FAQLink data={data} />:
            <FAQDetail data={data} />
            }
        </div>
    );
}
